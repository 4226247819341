export default {
  //indexedDB兼容
  indexedDB: window.indexedDB || window.webkitindexedDB || window.msIndexedDB || window.mozIndexedDB,

  //打开数据库
  // 新对象储存空间newStore参数：newStore.name、newStore.key
  // 新增对象存储空间要更改数据库版本
  openDB: function (dbname, version, newStore) {
    return new Promise(resolve=>{
      let newVersion = version || 1;
      let request = this.indexedDB.open(dbname, newVersion);
      request.onerror = function () {
        console.log("IndexedDB数据库打开失败")
      }
      request.onsuccess = function (event) {
        let db = event.target.result;
        resolve(db)
      }
      // onupgradeneeded，调用创建新的储存空间（版本升级也会触发）
      request.onupgradeneeded = function (event) {
        let db = event.target.result;
        if (newStore) {
          if (!db.objectStoreNames.contains(newStore.name)) {
            //新建一个model表格，主键为panelId
            let objectStore = db.createObjectStore(newStore.name, {
              keyPath: newStore.keyPath //主键keyPath='panelId'
            })
            console.log(objectStore)
            //这里可以添加索引
            // objectStore.createIndex('email', 'email', { unique: true });
          }
        }
      }
    })
  },
  //删除数据库
  deleteDB: function (dbname) {
    return new Promise(resolve=>{
      let deleteQuest = this.indexedDB.deleteDatabase(dbname);
      deleteQuest.onerror = function () {
        console.log("删除数据库出错")
        resolve(false)
      }
      deleteQuest.onsuccess = function () {
        console.log("删除数据库成功")
        resolve(true)
      }
    })
  },
  //关闭数据库
  closeDB: function (db) {
    db.close();
    console.log("数据库已关闭")
  },

  //写入数据
  addData: async function(db, storeName, objValue) {
    if(!db){
      db = await this.openDB("modelData",1,{name:'model',keyPath:'panelId'});
    }
    let request = db.transaction(storeName, 'readwrite').objectStore(storeName).add(objValue)
    request.onerror = function () {
      // console.log("数据写入失败/已存在")
    }
    request.onsuccess = function () {
      // console.log("数据写入成功")
    }
  },

  //根据板件id更新数据
  updateData: function (db, storeName, panelId, obj) {
    let transaction = db.transaction(storeName, 'readwrite');
    let store = transaction.objectStore(storeName);
    let request = store.get(panelId);
    return new Promise((resolve, reject) => {
      request.onsuccess = function (e) {
        let stocktable = e.target.result;
        if (stocktable) {
          stocktable.modeldata = obj
          resolve(store.put(stocktable)); //IDBObject.put()方法
        } else {
          reject(false);
        }
      }
    })
  },

  //删除数据
  deleteData: function (db, storename, panelId) {
    let store = db.transaction(storename, 'readwrite').objectStore(storename);
    let request = store.delete(panelId); //IDBObjectStore.delete()
    return new Promise(resolve => {
      request.onerror = function () {
        console.log("删除失败");
        resolve(false)
      }
      request.onsuccess = function () {
        console.log("删除成功")
        resolve(true)
      }
    })
  },

  //清空表所有数据
  clearStoreData: async function (db, storename) {
    if(!db){
      db = await this.openDB("modelData",1,{name:'model',keyPath:'panelId'});
    }
    let store = db.transaction(storename, 'readwrite').objectStore(storename);
    let request = store.clear();
    return new Promise(resolve => {
      request.onerror = function () {
        resolve(false)
      }
      request.onsuccess = function () {
        resolve(true)
      }
    })
  },


  //读取数据
  read: function (db, storename, panelId) {
    let store = db.transaction(storename, 'readwrite').objectStore(storename);
    let request = store.get(panelId); //通过主键获取对应数据objectStore.get()
    return new Promise(resolve => {
      request.onerror = function () {
        console.log("单条数据读取失败")
        resolve(false)
      }
      request.onsuccess = function (event) {
        resolve(event.target.result);
      }
    })
  },

  //读取所有数据
  readAll: function (db, storename) {
    var objectStore = db.transaction(storename).objectStore(storename);
    let request = objectStore.openCursor(); //openCursor()遍历数据
    var dataList = [];
    return new Promise((resolve) => {
      request.onerror = function () {
        console.log("单条数据读取失败")
        resolve(false)
      }
      request.onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          dataList.push(cursor.value)
          cursor.continue();
        } else {
          resolve(dataList);
        }
      };
    })
  },

  //通过索引查找信息
  readDataByIndexes: function (db, storename, indexValue) {
    let store = db.transaction(storename, 'readonly').objectStore(storename);
    let index = store.index(indexValue); //xxx值初始化数据库onupgradeneeded新建的索引objectStore.createIndex
    let request = index.get('张三');
    return new Promise(resolve => {
      request.onerror = function () {
        console.log("根据索引读取数据失败")
        resolve(false)
      }
      request.onsuccess = function (event) {
        resolve(event.target.result);
      }
    })
  }

}